const select = {
  styleOverrides: {
    select: {
      padding: '0',
      width: '100% !important',
    },
  },
};

export default select;
