import React from 'react';
import ArgonTypography from '../../components/ArgonTypography';
import ArgonBox from '../../components/ArgonBox';

export default function Fallback() {
  return (
    <ArgonBox
      sx={{
        height: 'calc(100vh - 100px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <ArgonTypography
        sx={{
          fontSize: '2rem'
        }}
      >
        Oops! Something went wrong...
      </ArgonTypography>
      <ArgonTypography
        sx={{
          fontSize: '1rem'
        }}
      >
        We couldn't render the page due to some problem. Please try again later.
      </ArgonTypography>
    </ArgonBox>
  )
}
