// react-router components
import { useLocation, Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";

// Argon Dashboard 2 MUI components
import ArgonBox from "../../../components/ArgonBox";
import ArgonTypography from "../../../components/ArgonTypography";

// Argon Dashboard 2 MUI example components
import Breadcrumbs from "../../Breadcrumbs";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
} from "../../Navbars/DashboardNavbar/styles";

// Argon Dashboard 2 MUI context
import {
  useController,
  setMiniSidenav,
  setUser,
} from "../../../context";

function DashboardNavbar({ isInvalidRoute, absolute, light, isMini }) {
  const [controller, dispatch] = useController();
  const {
    miniSidenav,
    transparentNavbar,
    user,
  } = controller;

  const route = useLocation().pathname.split("/").slice(1);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

  const handleLogout = () => {
    localStorage.removeItem("token");
    setUser(dispatch, null);
    localStorage.removeItem("user");
  };

  return (
    <AppBar
      position="fixed"
      color="info"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <ArgonBox
          sx={(theme) => ({
            ...navbarRow(theme, { isMini }),
            justifyContent: "flex-start",
          })}
        >
          <Icon
            fontSize="medium"
            sx={navbarDesktopMenu}
            onClick={handleMiniSidenav}
          >
            {miniSidenav ? "menu_open" : "menu"}
          </Icon>
          <IconButton
            size="small"
            color={light && transparentNavbar ? "white" : "dark"}
            sx={navbarMobileMenu}
            onClick={handleMiniSidenav}
          >
            <Icon>{miniSidenav ? "menu_open" : "menu"}</Icon>
          </IconButton>
          <Breadcrumbs
            icon="home"
            title={isInvalidRoute ? 'Not Found' : route[route.length - 1]}
            route={route}
            light={transparentNavbar ? light : false}
          />
        </ArgonBox>
        {isMini ? null : (
          <ArgonBox sx={(theme) => navbarRow(theme, { isMini })}>
            <ArgonBox color={light ? "white" : "inherit"}>
              <Link to="/profile">
                <IconButton sx={navbarIconButton} size="small">
                  <Icon
                    sx={({ palette: { dark, white } }) => ({
                      color: light ? white.main : dark.main,
                    })}
                  >
                    account_circle
                  </Icon>
                  <ArgonTypography
                    variant="button"
                    fontWeight="medium"
                    color={light && transparentNavbar ? "white" : "dark"}
                    mr={2}
                  >
                    {user?.fullName}
                  </ArgonTypography>
                </IconButton>
              </Link>
              <Link to="/authentication/sign-in">
                <ArgonTypography
                  variant="button"
                  fontWeight="medium"
                  color={light && transparentNavbar ? "white" : "dark"}
                  onClick={handleLogout}
                >
                  Log out
                </ArgonTypography>
              </Link>
            </ArgonBox>
          </ArgonBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: true,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
