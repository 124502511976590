import axios from 'axios';

// Determine the host based on the environment (development or production)
let host;
if (process.env.NODE_ENV === 'production') {
  host = window.location.origin;
} else {
  host = `${window.location.protocol}//localhost:3000`;
}

const httpClient = () => {
  const token = localStorage.getItem('token');
  return axios.create({
    baseURL: host,
    headers: {
      ...(token && { 'Authorization': `Bearer ${token}` })
    }
  })
};

export { host, httpClient }