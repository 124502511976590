import { useState, useEffect, Suspense } from "react";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

import ArgonBox from "./components/ArgonBox";
import Spinner from "./components/Spinner";

import DashboardLayout from "./helpers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "./helpers/Navbars/DashboardNavbar";
import Sidenav from "./helpers/Sidenav";
import Configurator from "./helpers/Configurator";

import theme from "./assets/theme";
import themeDark from "./assets/theme-dark";

import routes from "./routes";

import NotFound from "./pages/notFound";
import Fallback from "./pages/fallback";

import {
  useController,
  setMiniSidenav,
  setOpenConfigurator,
} from "./context";

// Icon Fonts
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";

import * as AdminService from './utils/api/services/admin';

export default function App() {
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const [isInvalidRoute, setIsInvalidRoute] = useState(false);
  const [controller, dispatch] = useController();
  const {
    miniSidenav,
    direction,
    openConfigurator,
    darkMode,
    user,
  } = controller;

  const [onMouseEnter, setOnMouseEnter] = useState(false);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (pathname === '/') {
      navigate('/dashboard')
    }
  }, [pathname]);

  // Initialize an admin account when the application is first launched
  useEffect(() => {
    const createAdmin = async () => {
      await AdminService.createAdmin();
    };
    createAdmin();
  }, []);

  // User has not setup password
  useEffect(() => {
    if (user && user.password === "") {
      navigate(`/register-user/${user.id}`);
    }
  }, [user]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => (
      <Route
        exact
        path={route.route}
        element={route.component}
        key={route.key}
      />
    ));

  const configsButton = (
    <ArgonBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.5rem"
      height="3.5rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="default" color="inherit">
        settings
      </Icon>
    </ArgonBox>
  );

  if (state?.isFallback) {
    return (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        <Suspense fallback={<Spinner />}>
          <Routes>
            <Route path="/fallback-page" element={<Fallback />} />
          </Routes>
        </Suspense>
      </ThemeProvider>
    );
  }
  else if (pathname.includes("/register-user/")) {
    return (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        <Configurator />
        {configsButton}
        <Suspense fallback={<Spinner />}>
          <Routes>
            {getRoutes(routes)}
            <Route path="*" element={<Navigate to={pathname} />} />
          </Routes>
        </Suspense>
      </ThemeProvider>
    );
  } else if (pathname.includes("/register-student-via-link/")) {
    if (user) {
      navigate("/dashboard", {
        state: {
          snackbarMessage: "Apologies, but it seems like you cannot access this page."
        },
      });
    } else {
      return (
        <ThemeProvider theme={darkMode ? themeDark : theme}>
          <CssBaseline />
          <Configurator />
          {configsButton}
          <Suspense fallback={<Spinner />}>
            <Routes>
              {getRoutes(routes)}
              <Route path="*" element={<Navigate to={pathname} />} />
            </Routes>
          </Suspense>
        </ThemeProvider>
      );
    }
  } else if (!user && pathname !== "/authentication/sign-in") {
    return (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        <Suspense fallback={<Spinner />}>
          <Navigate to="/authentication/sign-in" />
        </Suspense>
      </ThemeProvider>
    );
  }
  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {user ? (
        <>
          <Sidenav
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            user={user}
          />
          <Configurator />
          {configsButton}
          <DashboardLayout>
            <DashboardNavbar isInvalidRoute={isInvalidRoute} />
            <Suspense fallback={<Spinner />}>
              <Routes>
                {getRoutes(routes)}
                <Route path="*" element={<NotFound setIsInvalidRoute={setIsInvalidRoute} />} />
              </Routes>
            </Suspense>
          </DashboardLayout>
        </>
      ) :
        <Suspense fallback={<Spinner />}>
          <Routes>
            {getRoutes(routes)}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      }
    </ThemeProvider>
  );
}
