/** 
  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Sidenav.
  2. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  3. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  4. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  5. The `route` key is used to store the route location which is used for the react router.
  6. The `href` key is used to store the external links location.
  7. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  8. The `component` key is used to store the component of its route.
*/
import { lazy } from "react";

// Argon Dashboard 2 MUI components
import ArgonBox from "./components/ArgonBox";
const Dashboard = lazy(() => import('./layouts/dashboard'));
const Profile = lazy(() => import('./layouts/profile'));
const SignIn = lazy(() => import('./pages/authentication/signIn'));
const RegisterUser = lazy(() => import('./layouts/RegisterUser'));
const RegisterUserViaEmail = lazy(() => import('./layouts/RegisterUserViaEmail'));
const RegisterStudentViaLink = lazy(() => import('./pages/registerStudentViaLink'));
const UpdateProfile = lazy(() => import('./layouts/UpdateProfile'));
const RegisterEvent = lazy(() => import('./layouts/RegisterEvent'));
const UpdateEvent = lazy(() => import('./layouts/UpdateEvent'));
const RowDetail = lazy(() => import('./layouts/RowDetail'));
const PendingUsers = lazy(() => import('./layouts/dashboard/PendingUsers'));
const RegisteredUsers = lazy(() => import('./layouts/dashboard/RegisteredUsers'));
const Fallback = lazy(() => import('./pages/fallback'));

const routes = [
  {
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: (
      <ArgonBox
        component="i"
        color="primary"
        fontSize="14px"
        className="ni ni-tv-2"
      />
    ),
    component: <Dashboard />,
  },
  {
    name: "Profile",
    key: "profile",
    route: "/profile",
    icon: (
      <ArgonBox
        component="i"
        color="dark"
        fontSize="14px"
        className="ni ni-single-02"
      />
    ),
    component: <Profile />,
  },
  {
    name: "Sign In",
    key: "sign-in",
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "route",
    name: "Register user",
    key: "register-user",
    route: "/register-user",
    component: <RegisterUser />,
  },
  {
    name: "Update user",
    key: "update-user",
    route: "/update-profile/:userId",
    component: <UpdateProfile />,
  },
  {
    name: "Register user via Link",
    key: "register-user-via-link",
    route: "/register-user/:userId",
    component: <RegisterUserViaEmail />,
  },
  {
    name: "Register event",
    key: "register-event",
    route: "/register-event",
    icon: (
      <ArgonBox
        component="i"
        color="info"
        fontSize="14px"
        className="ni ni-collection"
      />
    ),
    component: <RegisterEvent />,
  },
  {
    name: "Update event",
    key: "update-event",
    route: "/register-event/:id",
    component: <UpdateEvent />,
  },
  {
    name: "Row detail page",
    key: "row-detail",
    route: "/event-detail",
    component: <RowDetail />,
  },
  {
    name: "Registered users",
    key: "registered-users",
    route: "/registered-users",
    icon: (
      <ArgonBox
        component="i"
        color="primary"
        fontSize="14px"
        className="ni ni-badge"
      />
    ),
    component: <RegisteredUsers />,
  },
  {
    name: "Pending users",
    key: "pending-users",
    route: "/pending-users",
    icon: (
      <ArgonBox
        component="i"
        color="primary"
        fontSize="14px"
        className="ni ni-email-83"
      />
    ),
    component: <PendingUsers />,
  },
  {
    name: "Register Student",
    key: "register-student",
    route: "/register-student-via-link",
    component: <RegisterStudentViaLink />,
  },
  {
    name: "Fallback",
    key: "fallback-page",
    route: "/fallback-page",
    component: <Fallback />,
  },
];

export default routes;
