

// Argon Dashboard 2 MUI base styles
import colors from "../../base/colors";
import boxShadows from "../../base/boxShadows";
import borders from "../../base/borders";

const { background } = colors;
const { cardBoxShadow } = boxShadows;
const { borderRadius } = borders;

const tableContainer = {
  styleOverrides: {
    root: {
      backgroundColor: background.dark,
      boxShadow: cardBoxShadow,
      borderRadius: borderRadius.xl,
    },
  },
};

export default tableContainer;
