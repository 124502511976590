import React, { useEffect } from 'react'
import ArgonTypography from '../../components/ArgonTypography'
import ArgonBox from '../../components/ArgonBox'
import { Link } from 'react-router-dom'

export default function NotFound({ setIsInvalidRoute }) {
  useEffect(() => {
    // This code runs when the component mounts
    setIsInvalidRoute(true);
    return () => {
      // This code runs when the component unmounts 'componentWillUnmount'
      setIsInvalidRoute(false);
    };
  }, []);
  return (
    <ArgonBox
      sx={{
        height: 'calc(100vh - 100px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <ArgonTypography
        sx={{
          fontSize: '8rem'
        }}
      >
        404
      </ArgonTypography>
      <ArgonTypography
      sx={{
        fontSize: '2rem'
      }}
      >
        Oops! This is not the webpage you are looking for...
      </ArgonTypography>
      <Link to="/dashboard" style={{ fontSize: '1rem' }}>Click here to go home</Link>
    </ArgonBox>
  )
}
