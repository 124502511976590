import { useEffect } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Argon Dashboard 2 MUI components
import ArgonBox from "../../components/ArgonBox";
import ArgonTypography from "../../components/ArgonTypography";

// Argon Dashboard 2 MUI example components
import SidenavItem from "./SidenavItem";

// Custom styles for the Sidenav
import SidenavRoot from "./SidenavRoot";

// Argon Dashboard 2 MUI context
import { useController, setMiniSidenav } from "../../context";
import { USER_TYPE } from "../../utils/constants/user";

function Sidenav({ routes, ...rest }) {
  const [controller, dispatch] = useController();
  const { miniSidenav, darkSidenav, layout, user } = controller;
  const location = useLocation();
  const { pathname } = location;
  const itemName = pathname.split("/").slice(1)[0];

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(({ name, icon, key, route }) => {
    const returnValue = (
      <NavLink to={route} key={key}>
        <SidenavItem name={name} icon={icon} active={key === itemName} />
      </NavLink>
    );
    return returnValue;
  });
  const sideList = renderRoutes.filter((el) =>
    ['profile', 'dashboard', ...(user.type !== USER_TYPE.STUDENT ? ['registered-users', 'pending-users'] : [])].includes(el.key)
  );

  return (
    <SidenavRoot {...rest} variant="permanent" ownerState={{ darkSidenav, miniSidenav, layout }}>
      <ArgonBox pt={3} pb={1} px={4} textAlign="center">
        <ArgonBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <ArgonTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </ArgonTypography>
        </ArgonBox>
      </ArgonBox>
      <Divider light={darkSidenav} />
      <List>{sideList}</List>

    </SidenavRoot>
  );
}

Sidenav.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
