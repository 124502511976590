

// @mui material components
import Fade from "@mui/material/Fade";

// Argon Dashboard 2 MUI base styles
import colors from "../base/colors";
import typography from "../base/typography";
import borders from "../base/borders";

// Argon Dashboard 2 MUI helper functions
import pxToRem from "../functions/pxToRem";

const { light } = colors;
const { size, fontWeightRegular } = typography;
const { borderRadius } = borders;

const tooltip = {
  defaultProps: {
    arrow: true,
    TransitionComponent: Fade,
  },

  styleOverrides: {
    tooltip: {
      maxWidth: pxToRem(200),
      color: light.main,
      fontSize: size.xs,
      fontWeight: fontWeightRegular,
      borderRadius: borderRadius.md,
      opacity: 0.7,
    },
  },
};

export default tooltip;
